import _ from 'lodash';
import { proxyFetch } from './proxy';
import { NewProductModelClient } from '@rss-engineering/product-model-tools';

const getProductModelClient = _.memoize(() => NewProductModelClient({
  baseUrl: process.env.REACT_APP_PRODUCT_MODEL_URL,
  fetch: proxyFetch
}));

export const supportedProductModelAccounts = {
  FANATICAL_SUPPORT_FOR_AWS: 'FANATICAL_SUPPORT_FOR_AWS',
  MANAGED_GCP: 'MANAGED_GCP',
  AZURE: 'AZURE'
};

export const isProductSupported = (product) =>
  product.businessUnit === 'public'
  && product.allowedAccountTypes.some((accountType) => accountType in supportedProductModelAccounts)
  && !_.isEmpty(_.get(product, 'extensions.cmsAddon'));

const parseJanusExtensions = (product) => {
  const supportedExtensions = {
    janusAws: supportedProductModelAccounts.FANATICAL_SUPPORT_FOR_AWS,
    janusGcp: supportedProductModelAccounts.MANAGED_GCP,
    janusAzure: supportedProductModelAccounts.AZURE
  };
  const matchingExtensions = _.intersection(_.keys(supportedExtensions), _.keys(product.extensions));
  const janusExtensions = {};
  for (const extension of matchingExtensions) {
    const extensionKey = supportedExtensions[extension];
    janusExtensions[extensionKey] = { ...product.extensions[extension] };
  }
  return janusExtensions;
};

const parseCmsExtension = (product) => {
  const cmsAddon = _.get(product, 'extensions.cmsAddon', {});
  return _.mapValues(cmsAddon, _.toLower);
};

const parseExtensions = (product) => {
  const janusExtensions = parseJanusExtensions(product);
  const cmsExtension = parseCmsExtension(product);
  return { ...cmsExtension, ...janusExtensions };
};

/**
 * @returns {
 *   cmsNamespace: {
 *     [ACCOUNT_TYPE]: { ...janusExtension },
 *     cmsNamespace: "cmsNamespace"
 *   },
 *   ...
 * }
 */
export const buildCatalog = (rawProducts) => _(rawProducts)
  .omit('sha')
  .filter(isProductSupported)
  .map(parseExtensions)
  .groupBy('cmsNamespace')
  .mapValues(_.spread(_.merge))
  .value();

export const fetchProductModel = async () => {
  const client = getProductModelClient();
  return await client
    .getProducts({ version: process.env.REACT_APP_PRODUCT_MODEL_VERSION })
    .then(buildCatalog);
};
